<template>
  <CForm @submit.prevent="save" method="POST">
    <CCard no-header>
      <CCardBody>
        <h3>{{ operationName }} Policy</h3>
        <CAlert v-if="message" :color="alertType"> {{ message }}</CAlert>

        <CRow>
          <CCol>
            <CInput label="Key" type="text" v-model="policy.key"></CInput
          ></CCol>
          <CCol col="9"></CCol>
        </CRow>
        <CRow>
          <CCol>
            <label style="width: 95%"
              >Policy Content
              <a @click="toggleContentTranslations = !toggleContentTranslations"
                ><CIcon
                  name="cil-globe-alt"
                  style=""
                  class="text-primary"
                ></CIcon
              ></a>
            </label>
            <br />
            <br />
            <div
              class="input-group"
              style=""
              v-show="!toggleContentTranslations"
              v-if="policy.contentTranslations[0]"
            >
              <div class="input-group-prepend" style="margin-left: 15px">
                <span
                  v-text="policy.contentTranslations[0].languageCode"
                  class="input-group-text"
                />
              </div>
              <CCol sm="12">
                <CTextarea
                  label=""
                  rows="10"
                  @id="policy.contentTranslations[0].languageCode"
                  v-model="policy.contentTranslations[0].content"
                />
              </CCol>
            </div>
            <div
              v-for="(translation, k) in policy.contentTranslations"
              :key="k"
              v-show="toggleContentTranslations"
            >
              <div class="input-group" style="padding-bottom: 5px">
                <div class="input-group-prepend" style="margin-left: 15px">
                  <span
                    v-text="translation.languageCode"
                    class="input-group-text"
                  />
                </div>
                <CCol sm="12">
                  <CTextarea
                    label=""
                    rows="10"
                    
                    @id="translation.languageCode"
                    v-model="policy.contentTranslations[k].content"
                  />
                </CCol>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="8"></CCol>
          <CCol class="d-flex justify-content-end" style="margin-top: 20px">
            <CButton
              color="secondary"
              class="btn-lg"
              @click="goBack"
              style="margin-right: 15px"
              ><CIcon name="cil-list" /> Back to the list</CButton
            >
            <CButton color="primary" type="submit"
              ><CIcon name="cil-save" /> Save</CButton
            >
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </CForm>
</template>

<script>
import axios from "axios";
export default {
  name: "AddNewPolicy",
  data: () => {
    return {
      operationName: null,
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      toggleContentTranslations: false,
      languages: [],
      isEdit : false,
      policy: {
        id: "00000000-0000-0000-0000-000000000000",
        key: null,
        partnerId: "00000000-0000-0000-0000-000000000000",
        contentTranslations: [],
      },
      contentTranslations: [],

      //Alert
      message: null,
      alertType: "danger",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    save() {
      let self = this;
      axios
        .post(`${this.$apiAdress}/v1/Policy`, self.policy)
        .then((response) => {
          self.policy.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created policy.";
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
    saveContentTranslation: function (value, event) {
      this.policy.contentTranslations[value].content = event.target.value;
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/Languages?showDefaultValue=false`)
        .then(function (response) {
          self.languages = response.data;
          response.data.forEach(function (language) {
            var translation = {
              languageCode: language.languageCode,
              content: "",
            };
            self.contentTranslations.push(translation);
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
      getLanguagesForCreate(){
        let self = this;
        axios
          .get(this.$apiAdress + "/v1/Language")
          .then(function (response) {

            response.data.map(function (value,key) {
               value.languageCode = value.languageCode;
               value.content = "";
               self.policy.contentTranslations.push(value);
            });
          })
          .catch(function (error) {
            this.message = error;
          });
      },
    getPolicy(id) {
      let self = this;
      self.getLanguages();
      axios
        .get(`${this.$apiAdress}/v1/Policy/${id}`)
        .then(function (response) {
          self.policy = response.data;
          self.check();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    check() {
      let self = this;
      let a = self.policy.contentTranslations.map((a) => a.languageCode);
      let b = self.contentTranslations.map((b) => b.languageCode);
    },
  },

  mounted: function () {
    let self = this;

    if (self.$route.params.id != this.emptyGuid) {
      self.operationName = "Edit";
      self.getLanguages();
      self.isEdit = true;
      self.getPolicy(self.$route.params.id);
    } else {
      self.operationName = "Create New";
      self.policy.contentTranslations = self.contentTranslations;
      self.getLanguagesForCreate();
    }

  },
};
</script>